import Vue from '../../main.js';

const serviceUrl = '/'; 
const getCommodity= `commodity`
const getDoor = `door/all`
const getPackaging = `packaging`
const getDoorAvailable = `door/availables`
const getCommodityTemperatureAll= `commodity/temperaturerange`

/**
 * Export
 */
export default {
  async getDoor() {
    return Vue.http.get(getDoor);
  },
  async getCommodity() {
    return Vue.http.get(getCommodity);
  },
  async getCommodityTemperatureAll() {
    return Vue.http.get(getCommodityTemperatureAll);
  },
  async getCommodityTemperature(id) {
    const query = id? 'id=${id}': '';
    return Vue.http.get(`${getCommodityTemperatureAll}?query`);
  },
  async getAvailableDoor(currentDoorID) {
    return Vue.http.get(`${getDoorAvailable}?id=${currentDoorID}`);
  },
  async getPackaging() {
    return Vue.http.get(getPackaging);
  }
}