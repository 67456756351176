<template>
  <v-container id="dashboard" fluid tag="section">
    <v-flex xs12 sm12 md12>
      <material-card>
        <template v-slot:heading>
          <div class="display-2 font-weight-light">Dashboard</div>
        </template>
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-layout class="txtSearch" wrap row>
              <v-flex md3 xs12>
                <v-text-field
                  md3
                  solo
                  class="right-input input-txtsearch"
                  v-model="search"
                  maxlength="13"
                  placeholder="Search order:"
                  append-icon="fas fa-search"
                ></v-text-field>
              </v-flex>

              <v-btn
                xs2
                color="orange"
                @click="showDone = true"
                dark
                v-if="!showDone"
              >
                Show done
                <v-icon right>fas fa-eye</v-icon>
              </v-btn>
              <v-btn
                color="orange"
                @click="showDone = false"
                dark
                v-if="showDone"
              >
                Hide done
                <v-icon right>fas fa-eye-slash</v-icon>
              </v-btn>
            </v-layout>
          </v-flex>
          <v-flex :md6="showDone" :md12="!showDone" sm12>
            <material-card
              icon="mdi-location-enter"
              title="Pending Inbound/Entradas Pendientes"
              color="blue darken-1"
            >
              <v-data-table
                id="inbound-pending"
                :headers="inbound.pending.headers"
                :items="inbound.pending.data"
                :options.sync="inbound.pending.options"
                :server-items-length="inbound.pending.totalItems"
                :loading="inbound.pending.isLoading"
                :key="inbound.pending.data.length"
                item-key="po"
                class="elevation-1 inbound-pending dashboard"
                :items-per-page="-1"
              >
                <template v-slot:[`item.checkInDate`]="{ item }">
                  {{ $moment(item.checkInDate).format("HH:mm") }}
                </template>
                <template v-slot:[`item.comments`]="{ item }">
                  <div class="columnComments">{{ item.comments }}</div>
                </template>
                <template v-slot:[`item.inOut`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.inOut"
                    @save="saveInboundInOut(item)"
                    large
                    persistent
                  >
                    <div class="div-status-dashboard">
                      {{ item.inOut }}
                    </div>
                    <template v-slot:input>
                      <integer-input
                        :min="0"
                        :max="1000"
                        :allow_negative="false"
                        v-model="item.inOut"
                      ></integer-input>
                    </template>
                  </v-edit-dialog>
                </template>
                <template
                  v-slot:[`item.actions`]="{ item, index }"
                  class="justify-center layout px-0"
                >
                  <v-icon
                    small
                    class="mr-2"
                    @click="goInbound(item)"
                    title="Edit/Editar"
                    >mdi-more</v-icon
                  >
                  <v-icon
                    v-if="item.removable && (item.erase || false) == false"
                    small
                    class="mr-2"
                    @click="removeInbound(item, index)"
                    title="Remove/Eliminar"
                    >mdi-delete</v-icon
                  >
                </template>
                <template slot="pageText" slot-scope="props"
                  >Lines {{ props.pageStart }} - {{ props.pageStop }} de
                  {{ props.itemsLength }}</template
                >
              </v-data-table>
            </material-card>
          </v-flex>
          <v-flex md6 sm12 v-if="showDone">
            <material-card
              icon="mdi-check-all"
              title="Done Inbound/Entradas Hechas"
            >
              <v-data-table
                :headers="inbound.done.headers"
                :items="inbound.done.data"
                :options.sync="inbound.done.options"
                :server-items-length="inbound.done.totalItems"
                :loading="inbound.done.isLoading"
                class="elevation-1 dashboard"
                item-key="po"
                :key="inbound.pending.data.length"
                :items-per-page="-1"
              >
                <template v-slot:[`item.checkInDate`]="{ item }">
                  {{ $moment(item.checkInDate).format("HH:mm") }}
                </template>
                <template v-slot:[`item.inOut`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.inOut"
                    @save="saveInboundInOut(item)"
                    large
                    persistent
                  >
                    <div class="div-status-dashboard">
                      {{ item.inOut }}
                    </div>
                    <template v-slot:input>
                      <integer-input
                        :min="0"
                        :max="1000"
                        :allow_negative="false"
                        v-model="item.inOut"
                      ></integer-input>
                    </template>
                  </v-edit-dialog>
                </template>
                <template
                  v-slot:[`item.actions`]="{ item }"
                  class="justify-center layout px-0"
                >
                  <v-icon
                    small
                    class="mr-2"
                    @click="goInbound(item)"
                    title="Edit/Editar"
                    >mdi-more</v-icon
                  >
                </template>
                <template slot="pageText" slot-scope="props"
                  >Lines {{ props.pageStart }} - {{ props.pageStop }} de
                  {{ props.itemsLength }}</template
                >
              </v-data-table>
            </material-card>
          </v-flex>
          <v-flex :md6="showDone" :md12="!showDone" sm12>
            <material-card
              icon="mdi-location-enter"
              title="Pending Outbound/Salidas Pendientes"
              color="blue darken-1"
            >
              <v-data-table
                :headers="outbound.pending.headers"
                :items="outbound.pending.data"
                :options.sync="outbound.pending.options"
                :server-items-length="outbound.pending.totalItems"
                :loading="outbound.pending.isLoading"
                class="elevation-1 dashboard"
                item-key="so"
                :items-per-page="-1"
                :item-class="statusColorDashboard"
              >
                <template v-slot:[`item.checkInDate`]="{ item }">
                  {{ $moment(item.checkInDate).format("HH:mm") }}
                </template>

                <template v-slot:[`item.comments`]="{ item }">
                  <div class="columnComments">{{ item.comments }}</div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.dashboardStatus"
                    @save="savePendingOutbound(item)"
                    large
                    persistent
                  >
                    <div
                      class="div-status-dashboard"
                      v-bind:class="statusColorDashboard(item)"
                    >
                      {{ statusDashboard(item.dashboardStatus) }}
                    </div>
                    <template v-slot:input>
                      <v-select
                        solo
                        class="right-input"
                        v-model="item.dashboardStatus"
                        :items="controls.status"
                        outline
                        item-text="text"
                        item-value="id"
                        :disabled="outbound.pending.isLoading"
                        required
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.inOut`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.inOut"
                    @save="saveOutboundInOut(item)"
                    large
                    persistent
                  >
                    <div class="div-status-dashboard">
                      {{ item.inOut }}
                    </div>
                    <template v-slot:input>
                      <integer-input
                        :min="0"
                        :max="1000"
                        :allow_negative="false"
                        v-model="item.inOut"
                      ></integer-input>
                    </template>
                  </v-edit-dialog>
                </template>
                <template
                  v-slot:[`item.actions`]="{ item, index }"
                  class="justify-center layout px-0"
                >
                  <v-icon
                    small
                    class="mr-2"
                    @click="goOutbound(item)"
                    title="Edit/Editar"
                    >mdi-more</v-icon
                  >
                  <v-icon
                    v-if="item.removable"
                    small
                    class="mr-2"
                    @click="removeOutbound(item, index)"
                    title="Remove/Eliminar"
                    >mdi-delete</v-icon
                  >
                </template>
                <template slot="pageText" slot-scope="props"
                  >Lines {{ props.pageStart }} - {{ props.pageStop }} de
                  {{ props.itemsLength }}</template
                >
              </v-data-table>
            </material-card>
          </v-flex>
          <v-flex md6 sm12 v-if="showDone">
            <material-card
              icon="mdi-check-all"
              title="Done Outbound/Salidas Hechas"
            >
              <v-data-table
                :headers="outbound.done.headers"
                :items="outbound.done.data"
                :options.sync="outbound.done.options"
                :server-items-length="outbound.done.totalItems"
                :loading="outbound.done.isLoading"
                id="outbound-pending"
                class="elevation-1 dashboard"
                :items-per-page="-1"
              >
                <template v-slot:[`item.checkInDate`]="{ item }">
                  {{ $moment(item.checkInDate).format("HH:mm") }}
                </template>
                <template v-slot:[`item.inOut`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.inOut"
                    @save="saveOutboundInOut(item)"
                    large
                    persistent
                  >
                    <div class="div-status-dashboard">
                      {{ item.inOut }}
                    </div>
                    <template v-slot:input>
                      <integer-input
                        :min="0"
                        :max="1000"
                        :allow_negative="false"
                        v-model="item.inOut"
                      ></integer-input>
                    </template>
                  </v-edit-dialog>
                </template>
                <template
                  v-slot:[`item.actions`]="{ item, index }"
                  class="justify-center layout px-0"
                >
                  <v-icon
                    small
                    class="mr-2"
                    @click="goOutbound(item)"
                    title="Edit/Editar"
                    >mdi-more</v-icon
                  >
                  <v-icon
                    v-if="item.removable && (item.erase || false) == false"
                    small
                    class="mr-2"
                    @click="removeOutbound(item, index)"
                    title="Remove/Eliminar"
                    >mdi-delete</v-icon
                  >
                </template>
                <template slot="pageText" slot-scope="props"
                  >Lines {{ props.pageStart }} - {{ props.pageStop }} de
                  {{ props.itemsLength }}</template
                >
              </v-data-table>
            </material-card>
          </v-flex>
          <v-flex md12 sm12>
            <material-card color="deep-orange" icon="mdi-door">
              <template v-slot:heading>
                <div class="display-2 font-weight-light">
                  <v-icon size="32"> mdi-door </v-icon>
                  Door Status/Estatus Puertas
                </div>
              </template>
              <v-container grid-list-md text-xs-center class="container">
                <v-layout row wrap justify-center>
                  <template v-for="d in doorsStatus">
                    <v-card
                      class="ma-2"
                      :color="d.used ? 'red' : 'green'"
                      :key="d.id"
                      large
                      :class="d.used ? 'door-used' : 'door-available'"
                    >
                      <v-card-title>
                        <div class="door-name">
                          {{ d.name }}
                        </div>
                      </v-card-title>
                      <v-card-subtitle class="door-order" v-if="d.order">
                        {{ d.order }}
                      </v-card-subtitle>
                    </v-card>
                  </template>
                </v-layout>
              </v-container>
            </material-card>
          </v-flex>
        </v-layout>
      </material-card>
    </v-flex>
  </v-container>
</template>

<script src="../../../signalr.js"></script><script>
import moment from "moment";
import inbound from "../../services/api/inbound";
import outbound from "../../services/api/outbound";
import common from "../../services/api/common";

import findLast from "lodash/findLast";

export default {
  name: "driverInbound",
  data() {
    return {
      inbound: {
        pending: {
          headers: [
            {
              text: "Vendor",
              align: "left",
              sortable: true,
              value: "grower",
            },
            {
              text: "PO",
              align: "left",
              sortable: true,
              value: "po",
            },
            {
              text: "Company",
              align: "left",
              sortable: true,
              value: "companyName",
            },
            {
              text: "Door",
              align: "center",
              sortable: false,
              value: "doorName",
            },
            {
              text: "CheckIn",
              align: "left",
              sortable: true,
              value: "checkInDate",
            },
            {
              text: "Diff",
              align: "end",
              sortable: false,
              value: "checkInDoorAssignment",
            },
            {
              text: "Comments",
              align: "left",
              sortable: false,
              value: "comments",
              class: "columnComments",
            },
            {
              text: "InOut",
              align: "left",
              sortable: false,
              value: "inOut",
            },
            {
              text: "Actions",
              align: "center",
              sortable: false,
              value: "actions",
            },
          ],
          data: [],
          pagination: {
            filters: "",
            bActivePaged: true,
            page: 1,
            itemsPerPage: 15,
            sortBy: "checkInDate",
            sortDesc: true,
          },
          options: {
            sortBy: ["checkInDate"],
            sortDesc: [true],
          },
          totalItems: 0,
          isLoading: false,
        },
        done: {
          headers: [
            {
              text: "Vendor",
              align: "left",
              sortable: true,
              value: "grower",
            },
            {
              text: "PO",
              align: "left",
              sortable: true,
              value: "po",
            },
            {
              text: "Company",
              align: "left",
              sortable: true,
              value: "companyName",
            },
            {
              text: "Door",
              align: "center",
              sortable: false,
              value: "doorName",
            },
            {
              text: "CheckIn",
              align: "left",
              sortable: true,
              value: "checkInDate",
            },
            {
              text: "Diff",
              align: "",
              sortable: false,
              value: "checkInDoorAssignment",
            },
            {
              text: "InOut",
              align: "left",
              sortable: false,
              value: "inOut",
            },
            {
              text: "Actions",
              align: "center",
              sortable: false,
              value: "actions",
            },
          ],
          data: [],
          pagination: {
            filters: "",
            bActivePaged: true,
            page: 1,
            itemsPerPage: 15,
            sortBy: "checkInDate",
            sortDesc: true,
          },
          options: {
            sortBy: ["checkInDate"],
            sortDesc: [true],
          },
          totalItems: 0,
          isLoading: false,
        },
      },
      outbound: {
        pending: {
          headers: [
            {
              text: "Customer",
              align: "left",
              sortable: true,
              value: "customer",
            },
            {
              text: "PU",
              align: "left",
              sortable: true,
              value: "so",
            },
            {
              text: "Carrier",
              align: "left",
              sortable: true,
              value: "carrier",
            },
            {
              text: "Door",
              align: "center",
              sortable: false,
              value: "doorName",
            },
            {
              text: "CheckIn",
              align: "left",
              sortable: true,
              value: "checkInDate",
            },
            {
              text: "Diff",
              align: "end",
              sortable: false,
              value: "checkInDoorAssignment",
            },
            {
              text: "Comments",
              align: "left",
              sortable: false,
              value: "comments",
              class: "columnComments",
            },
            {
              text: "Status",
              align: "left",
              sortable: false,
              value: "status",
            },
            {
              text: "InOut",
              align: "left",
              sortable: false,
              value: "inOut",
            },
            {
              text: "Actions",
              align: "center",
              sortable: false,
              value: "actions",
            },
          ],
          data: [],
          pagination: {
            filters: "",
            bActivePaged: true,
            page: 1,
            itemsPerPage: 15,
            sortBy: "checkInDate",
            sortDesc: true,
          },
          options: {
            sortBy: ["checkInDate"],
            sortDesc: [true],
          },
          totalItems: 0,
          isLoading: false,
        },
        done: {
          headers: [
            {
              text: "Customer",
              align: "left",
              sortable: true,
              value: "customer",
            },
            {
              text: "PU",
              align: "left",
              sortable: true,
              value: "so",
            },
            {
              text: "Carrier",
              align: "left",
              sortable: true,
              value: "carrier",
            },
            {
              text: "Door",
              align: "center",
              sortable: false,
              value: "doorName",
            },
            {
              text: "CheckIn",
              align: "left",
              sortable: true,
              value: "checkInDate",
            },
            {
              text: "Diff",
              align: "end",
              sortable: false,
              value: "checkInDoorAssignment",
            },
            {
              text: "InOut",
              align: "left",
              sortable: false,
              value: "inOut",
            },
            {
              text: "Actions",
              align: "center",
              sortable: false,
              value: "actions",
            },
          ],
          data: [],
          pagination: {
            filters: "",
            bActivePaged: true,
            page: 1,
            itemsPerPage: 15,
            sortBy: "checkInDate",
            sortDesc: true,
          },
          options: {
            sortBy: ["checkInDate"],
            sortDesc: [true],
          },
          totalItems: 0,
          isLoading: false,
        },
      },
      doors: [],
      search: "",
      rIn: null,
      rout: null,
      showDone: false,
      controls: {
        status: [
          { id: 0, text: "None", color: "row-none" },
          { id: 1, text: "Ready", color: "row-ready" },
          { id: 2, text: "Pending", color: "row-pending" },
          { id: 3, text: "Repack", color: "row-repack" },
          { id: 4, text: "Driver left", color: "row-driverleft" },
        ],
      },
    };
  },
  components: {
    MaterialCard: () => import("@/components/base/MaterialCard"),
    IntegerInput: () => import("@/components/fields/IntegerInput"),
  },
  created() {
    common.getDoor().then((data) => {
      this.doors = data.body.sort((a, b) =>
        parseInt(a.name) > parseInt(b.name) ? 1 : -1
      );
    });
    this.fetchData();
  },
  beforeDestroy() {
    clearTimeout(this.rIn);
    clearTimeout(this.rOut);
    this.rIn = false;
    this.rOut = false;
  },
  watch: {
    "inbound.pending.options": {
      handler() {
        this.getDashboardInboundPending(false);
      },
      deep: true,
    },
    "inbound.done.options": {
      handler() {
        this.getDashboardInboundDone(false);
      },
      deep: true,
    },
    "outbound.pending.options": {
      handler() {
        this.getDashboardOutboundPending(false);
      },
      deep: true,
    },
    "outbound.done.options": {
      handler() {
        this.getDashboardOutboundDone(false);
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDashboardInboundPending(false);
        this.getDashboardInboundDone(false);
        this.getDashboardOutboundPending(false);
        this.getDashboardOutboundDone(false);
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      this.getDashboardInboundPending(true);
      this.getDashboardInboundDone(true);

      this.getDashboardOutboundPending(true);
      this.getDashboardOutboundDone(true);
    },
    async getDashboardInboundPending(bActiveSetTimeout = true) {
      this.inbound.pending.isLoading = true;
      try {
        this.inbound.pending.pagination.filters = this.search;
        this.inbound.pending.pagination.bActivePaged = true;
        this.inbound.pending.pagination.page =
          this.inbound.pending.options.page;
        this.inbound.pending.pagination.itemsPerPage =
          this.inbound.pending.options.itemsPerPage;
        if (this.inbound.pending.options) {
          if (this.inbound.pending.options.sortBy)
            this.inbound.pending.pagination.sortBy =
              this.inbound.pending.options.sortBy[0];
          if (this.inbound.pending.options.sortDesc)
            this.inbound.pending.pagination.sortDesc =
              this.inbound.pending.options.sortDesc[0];
        }

        let OData = Object.assign({}, this.inbound.pending.pagination);
        OData.qaFormat = JSON.stringify(
          this.inbound.pending.pagination.qaFormat
        );

        const response = await inbound.dashboardPendingPaged(OData);
        this.inbound.pending.data = response.body.pending;
        this.inbound.pending.totalItems = response.body.totalItems;

        this.inbound.pending.isLoading = false;
      } catch (err) {
        console.log(err);
      } finally {
        if (this.rIn !== false && bActiveSetTimeout) {
          this.rIn = setTimeout(this.getDashboardInboundPending, 10000);
        }
      }
    },
    async getDashboardInboundDone(bActiveSetTimeout = true) {
      this.inbound.done.isLoading = true;
      try {
        this.inbound.done.pagination.filters = this.search;
        this.inbound.done.pagination.bActivePaged = true;
        this.inbound.done.pagination.page =
          this.inbound.done.options.page == 0
            ? 1
            : this.inbound.done.options.page;
        this.inbound.done.pagination.itemsPerPage =
          this.inbound.done.options.itemsPerPage == 0
            ? -1
            : this.inbound.done.options.itemsPerPage;
        if (this.inbound.done.options) {
          if (this.inbound.done.options.sortBy)
            this.inbound.done.pagination.sortBy =
              this.inbound.done.options.sortBy[0];
          if (this.inbound.done.options.sortDesc)
            this.inbound.done.pagination.sortDesc =
              this.inbound.done.options.sortDesc[0];
        }

        let OData = Object.assign({}, this.inbound.done.pagination);
        OData.qaFormat = JSON.stringify(this.inbound.done.pagination.qaFormat);
        const response = await inbound.dashboardDonePaged(OData);
        this.inbound.done.data = response.body.done;
        this.inbound.done.totalItems = response.body.totalItems;

        this.inbound.done.isLoading = false;
      } catch (err) {
        console.log(err);
      } finally {
        if (this.rIn !== false && bActiveSetTimeout) {
          this.rIn = setTimeout(this.getDashboardInboundDone, 10000);
        }
      }
    },
    async getDashboardOutboundPending(bActiveSetTimeout = true) {
      this.outbound.pending.isLoading = true;
      try {
        this.outbound.pending.pagination.filters = this.search;
        this.outbound.pending.pagination.bActivePaged = true;
        this.outbound.pending.pagination.page =
          this.outbound.pending.options.page;
        this.outbound.pending.pagination.itemsPerPage =
          this.outbound.pending.options.itemsPerPage;

        if (this.outbound.pending.options) {
          if (this.outbound.pending.options.sortBy)
            this.outbound.pending.pagination.sortBy =
              this.outbound.pending.options.sortBy[0];
          if (this.outbound.pending.options.sortDesc)
            this.outbound.pending.pagination.sortDesc =
              this.outbound.pending.options.sortDesc[0];
        }

        let OData = Object.assign({}, this.outbound.pending.pagination);
        OData.qaFormat = JSON.stringify(
          this.outbound.pending.pagination.qaFormat
        );

        const response = await outbound.dashboardPendingPaged(OData);
        this.outbound.pending.data = response.body.pending;
        this.outbound.pending.totalItems = response.body.totalItems;

        this.outbound.pending.isLoading = false;
      } catch (err) {
        console.log(err);
      } finally {
        if (this.rIn !== false && bActiveSetTimeout) {
          this.rIn = setTimeout(this.getDashboardOutboundPending, 10000);
        }
      }
    },
    async getDashboardOutboundDone(bActiveSetTimeout = true) {
      this.outbound.done.isLoading = true;
      try {
        this.outbound.done.pagination.filters = this.search;
        this.outbound.done.pagination.bActivePaged = true;
        this.outbound.done.pagination.page = this.outbound.done.options.page;
        this.outbound.done.pagination.itemsPerPage =
          this.outbound.done.options.itemsPerPage;

        if (this.outbound.done.options) {
          if (this.outbound.done.options.sortBy)
            this.outbound.done.pagination.sortBy =
              this.outbound.done.options.sortBy[0];
          if (this.outbound.done.options.sortDesc)
            this.outbound.done.pagination.sortDesc =
              this.outbound.done.options.sortDesc[0];
        }

        let OData = Object.assign({}, this.outbound.done.pagination);
        OData.qaFormat = JSON.stringify(this.outbound.done.pagination.qaFormat);
        const response = await outbound.dashboardDonePaged(OData);
        this.outbound.done.data = response.body.done;
        this.outbound.done.totalItems = response.body.totalItems;

        this.outbound.done.isLoading = false;
      } catch (err) {
        console.log(err);
      } finally {
        if (this.rIn !== false && bActiveSetTimeout) {
          this.rIn = setTimeout(this.getDashboardOutboundDone, 10000);
        }
      }
    },

    async getDashboardOutbound() {
      this.isLoading = true;
      try {
        var r = await outbound.getDashboard();
        this.outbound.pending.data = r.data.pending;
        this.outbound.done.data = r.data.done;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      } finally {
        if (this.rOut !== false) {
          this.rOut = setTimeout(this.getDashboardOutbound, 30000);
        }
      }
    },
    goInbound(item) {
      this.$router.push({
        name: "inbound",
        params: {
          id: item.id,
        },
      });
    },
    goOutbound(item) {
      this.$router.push({
        name: "outbound",
        params: {
          id: item.id,
        },
      });
    },
    async savePendingOutbound(item) {
      try {
        await outbound.saveDashboardStatus(item);
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    async saveInboundInOut(item) {
      try {
        await inbound.saveDashboardInOut(item);
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    async saveOutboundInOut(item) {
      try {
        await outbound.saveDashboardInOut(item);
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    async removeOutbound(item, index) {
      if (confirm("Are you sure you want to delete this item?")) {
        item.erase = true;
        await outbound.remove(item.id);
        $("tr:contains('" + item.so + "')").remove();
        const indexArray = this.outbound.pending.data.findIndex(
          (i) => i.so === item.so
        );
        this.outbound.pending.data.splice(indexArray, 1);
      }
    },
    async removeInbound(item, index) {
      if (confirm("Are you sure you want to delete this item?")) {
        item.erase = true;
        await inbound.remove(item.id);
        $("tr:contains('" + item.po + "')").remove();
        const indexArray = this.inbound.pending.data.findIndex(
          (i) => i.po === item.po
        );
        this.inbound.pending.data.splice(indexArray, 1);
      }
    },
    checkInFormat(date) {
      if (date) return moment(date).format("MM/DD/YY HH:mm:ss");
      else return "";
    },
    statusDashboard(status) {
      const item = findLast(this.controls.status, function (n) {
        return status == n.id;
      });
      return item.text;
    },
    statusColorDashboard(currentItem) {
      const item = findLast(this.controls.status, function (n) {
        return currentItem.dashboardStatus == n.id;
      });
      return item.color;
    },
  },
  computed: {
    doorsStatus() {
      const currentDoors = this.doors;
      currentDoors.forEach((d) => {
        const assignInbound = this.inbound.pending.data.filter(
          (wd) => wd.doorName == d.name
        );
        const assignOutbound = this.outbound.pending.data.filter(
          (wd) => wd.doorName == d.name
        );
        const assign = assignInbound.concat(assignOutbound);
        if (assign) {
          const orders = assign.map((a) => a.so || a.po);
          if (assign.length > 0) {
            d.used = true;
            d.order = orders.join(", ");
          }
        }
      });
      return currentDoors;
    },
  },
};
</script>

<style>
.tables-inside {
  padding-top: 5px;
}

.dashboard .v-toolbar__content {
  height: 30px !important;
}

.dashboard-info {
  padding-top: 0;
  padding-bottom: 0;
}

.dashboard-container {
  top: -30px;
  position: relative;
}

.door {
  padding-top: 0;
  margin-top: 0;
}

.door .container {
  margin: 0;
  padding: 0;
}

.door .text {
  font-size: 1.2em;
  font-weight: bolder !important;
}

.table-title {
  padding: 5px;
}

table.v-table tbody td,
table.v-table tbody th {
  height: 30px;
}

table.v-table thead tr {
  height: 36px;
}

.v-datatable__actions__range-controls {
  width: 230px !important;
}

.v-datatable__actions__select {
  width: 140px !important;
}

.v-datatable__actions__select .v-select {
  margin-left: 10px !important;
}

.v-datatable__actions__pagination {
  margin-right: 0px !important;
}

.door-forklift {
  font-size: 0.8em;
}

@keyframes Pulse {
  0% {
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
  }

  50% {
    box-shadow: 0 0 14px rgba(240, 9, 9, 0.87);
  }

  100% {
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
  }
}

.door-used {
  box-shadow: 0 0 0 rgba(230, 98, 36, 0.4);
  animation: Pulse 2s infinite ease-in-out;
}

.v-chip {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.txtSearch {
  margin-top: 20px;
  margin-bottom: -30px;
  padding-left: 30px;
}

.door-order {
  padding-top: 10px !important;
}

.door-name {
  margin: 0 auto;
}

.input-txtsearch {
  padding-right: 10px !important;
}

.columnComments {
  max-width: 800px;
}

.column-show-done {
  text-overflow: ellipsis;
}

.div-status-dashboard {
  padding: 4px;
}

.row-none {
  background-color: #fff;
}
.row-ready {
  background-color: #dcedc8;
}
.row-pending {
  background-color: #fff9c4;
}
.row-repack {
  background-color: #ffe0b2;
}
.row-driverleft {
  background-color: #ffccbc;
}

.dashboard > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1.3em;
}

.dashboard > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1.2em;
}

.dashboard > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 6px;
}
</style>
